import React from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import ViprasMartProfitAndLossReport from './ViprasMartProfitAndLossReport';
import ViprasPestControlProfitAndLoss from './ViprasPestControlProfitAndLoss';
import ViprasLaundryProfitAndLossReport from './ViprasLaundryProfitAndLossReport';
import ViprasCleaningProfitAndLossReport from './ViprasCleaningProfitAndLossReport';
import HkConsumableComparision from './HkConsumableComparision';
import OverHeadCalculation from './OverHeadCalculation';
import GarbageProfitAndLossReport from './GarbageProfitAndLossReport';
import DepartmentComparison from './DepartmentComparison';

const items = [
  {
    key: '1',
    label: 'DEEP CLEANING',
    children: <ViprasCleaningProfitAndLossReport />
  },
  {
    key: '2',
    label: 'V MART',
    children: <ViprasMartProfitAndLossReport />
  },
  {
    key: '3',
    label: 'PEST CONTROL',
    children: <ViprasPestControlProfitAndLoss />
  },
  {
    key: '4',
    label: 'LAUNDRY',
    children: <ViprasLaundryProfitAndLossReport />
  },
  {
    key: '5',
    label: 'GARBAGE DISPOSAL',
    children: <GarbageProfitAndLossReport />
  },
  {
    key: '6',
    label: 'DEPARTMENT COMPARISON',
    children: <DepartmentComparison />
  },
  {
    key: '7',
    label: 'HK CONSUMABLE COMPARISON',
    children: <HkConsumableComparision />
  },
  {
    key: '8',
    label: 'OVER HEAD CALCULATION',
    children: <OverHeadCalculation />
  }
];
const ProfitAndLossContainer = ({ activeKey }) => {
  return (
    <div>
      <Tabs defaultActiveKey={activeKey} type="card" size="large" items={items}>
        {items.map(item => (
          <Tabs.TabPane key={item.key} tab={item.label}>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
ProfitAndLossContainer.propTypes = {
  activeKey: PropTypes.string.isRequired
};
export default ProfitAndLossContainer;
