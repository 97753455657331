import axios from 'axios';
import { settings } from 'config';
import { monthNames } from 'helpers/utils';
import { get } from 'lodash';

const AddImprestHolderTransaction = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/impresttransaction`, data, {
    headers: {
      'erp-token': token
    }
  });

const getSubTransdetails = (subId, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/impresttransaction/${subId}`, {
    headers: {
      'erp-token': token
    }
  });
};

const updateSubTransdetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/impresttransaction/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const getImprestHolderTransaction = (id, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/impresttransaction/report/${id}/${
      month + 1
    }/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getImprestHolderAttendance = (id, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/byEmployee/report/${id}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getSiteExpenses = (month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/impresttransaction/site/report/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getSiteProfitLose = (month, year, token) => {
  const filter = {
    month: `${month}-${year}`
  };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/profit/lose?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getImprestExpenses = (month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/impresttransaction/imprest/report/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getImprestHolder = (filter, token) => {
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};
const getEmployeeVerification = token => {
  const filter = { isVerified: false };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/employee?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getTicketReport = (employeeId, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/CRM/getTicketInfo/${employeeId}/3/2024`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const fetchOtherDeductuionData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let defaultFilter = {
    from: null,
    to: null
  };

  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}&page=${offset}&limit=${pageSize}`;
  if (
    (pageFilter.from && pageFilter.from !== null) ||
    (pageFilter.to && pageFilter.to !== null) ||
    pageFilter.value.trim().length > 1
  ) {
    let filter = {
      from: pageFilter.from,
      to: pageFilter.to
    };
    if (pageFilter.value.trim().length > 1) {
      filter = { ...filter, [`${pageFilter.key}`]: pageFilter.value };
    }
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  }
  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const fetchImprestHolderData = async (
  page,
  pageSize,
  pageFilter,
  id,
  token
) => {
  const offset = page + 1;
  // const defaultFilter = { imprestholderId: id };

  let defaultFilter = {
    $or: [
      { isVerified: 'APPROVED' },
      { isVerified: 'REJECTED' },
      { isVerified: 'PENDING' }
    ],
    imprestholderId: id,
    from: null,
    to: null
  };

  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}&page=${offset}&limit=${pageSize}`;

  if (
    (pageFilter.from && pageFilter.from !== null) ||
    (pageFilter.to && pageFilter.to !== null) ||
    pageFilter.value.trim().length > 1
  ) {
    let filters = {
      from: pageFilter.from,
      to: pageFilter.to
    };
    if (pageFilter.value.trim().length > 1) {
      let filter = { ...filters, [`${pageFilter.key}`]: pageFilter.value };
      filter = {
        ...filter,
        $or: [
          { isVerified: 'APPROVED' },
          { isVerified: 'REJECTED' },
          { isVerified: 'PENDING' }
        ],
        imprestholderId: id
      };
      const serializedData = JSON.stringify(filter);
      url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
    }
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};
const fetchPendingImprestHolderData = async (
  page,
  pageSize,
  pageFilter,
  id,
  token
) => {
  const offset = page + 1;
  const defaultFilter = { isVerified: 'PENDING', from: null, to: null };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}&page=${offset}&limit=${pageSize}`;

  if (
    (pageFilter.from && pageFilter.from !== null) ||
    (pageFilter.to && pageFilter.to !== null) ||
    pageFilter.value.trim().length > 1
  ) {
    let filter = {
      from: pageFilter.from,
      to: pageFilter.to
    };
    if (pageFilter.value.trim().length > 1) {
      filter = { ...filter, [`${pageFilter.key}`]: pageFilter.value };
    }
    filter = { ...filter, isVerified: 'PENDING' };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  }
  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const fetchAccountsData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  const defaultFilter = { createType: 'ACCOUNTS', from: null, to: null };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}&page=${offset}&limit=${pageSize}`;

  if (
    (pageFilter.from && pageFilter.from !== null) ||
    (pageFilter.to && pageFilter.to !== null) ||
    pageFilter.value.trim().length > 1
  ) {
    let filter = {
      from: pageFilter.from,
      to: pageFilter.to
    };
    if (pageFilter.value.trim().length > 1) {
      filter = { ...filter, [`${pageFilter.key}`]: pageFilter.value };
    }
    filter = { ...filter, createType: 'ACCOUNTS' };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  }

  if (pageFilter.key === 'credit' || pageFilter.key === 'debit') {
    let filter = { [`${pageFilter.key}`]: 0 };
    filter = {
      ...filter,
      createType: 'ACCOUNTS',
      from: pageFilter.from,
      to: pageFilter.to
    };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const fetchAccountsDetails = async (token, status, month, year) => {
  const monthNameObj = monthNames.find(m => m.value === month - 1);
  const defaultFilter = {
    createType: 'ACCOUNTS',
    from: null,
    to: null,
    month: `${monthNameObj?.label}-${year}`
  };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  console.log(status);
  if (status === 'PAID') {
    const filter = {
      createType: 'ACCOUNTS',
      from: null,
      to: null,
      status: 'PAID'
    };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  } else if (status === 'NOT_PAID') {
    const filter = {
      createType: 'ACCOUNTS',
      from: null,
      to: null,
      status: 'NOT_PAID'
    };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  } else if (status === 'HOLD') {
    const filter = { status: 'HOLD' };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  } else {
    url = `${settings.apiUrl}/api/v1/impresttransaction?filter=${serializedData}`;
  }

  try {
    return await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const updateAccountsDetails = (data, status, token) =>
  axios.post(
    `${settings.apiUrl}/api/v1/impresttransaction/update/byids/${status}`,
    data,
    {
      headers: {
        'erp-token': token
      }
    }
  );

export default {
  AddImprestHolderTransaction,
  fetchOtherDeductuionData,
  fetchImprestHolderData,
  getImprestHolderTransaction,
  getImprestHolder,
  fetchAccountsData,
  getImprestHolderAttendance,
  getTicketReport,
  getSubTransdetails,
  updateSubTransdetails,
  fetchAccountsDetails,
  getEmployeeVerification,
  updateAccountsDetails,
  fetchPendingImprestHolderData,
  getSiteExpenses,
  getImprestExpenses,
  getSiteProfitLose
};
